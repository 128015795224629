<template>
  <a-card style="background-color: #fff;">
    <div :class="advanced ? 'search' : null">
      <a-form layout="horizontal">
        <div :class="advanced ? null : 'fold'" style="display: flex;width: 100%;">
          <a-form-item label="日期" style="width: 120px;">
            <a-select default-value="1" @change="switchDate" style="width: 80px;">
              <a-select-option value="1">
                日报
              </a-select-option>
              <a-select-option value="2">
                月报
              </a-select-option>
              <a-select-option value="3">
                年报
              </a-select-option>
              <a-select-option value="4">
                自定义
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item>
            <a-date-picker v-if="paramsObj.type == 1" :default-value="moment(moment(), 'YYYY-MM-DD')"
                           @change="dateChange"
                           :allowClear="false"/>
            <a-month-picker v-if="paramsObj.type == 2" @change="dateChange" :default-value="moment(moment(), 'YYYY-MM')"
                            :allowClear="false"/>
            <a-range-picker :ranges="{
                '今天': [moment(), moment()],
                '昨天': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
               '当月': [moment().startOf('month'), moment().endOf('month')],
  '上月': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
            }" v-if="paramsObj.type == 4" format="YYYY-MM-DD" :defaultValue="[moment(), moment()]"
                            @change="dateChange" :allowClear="false"/>
            <a-date-picker v-if="paramsObj.type == 3" mode="year" v-model="selectedYear" :open='open'
                           @openChange="openChangeOne" format="YYYY" valueFormat="YYYY" :allowClear="false"
                           @panelChange="panelChangeOne"/>
          </a-form-item>
          <div style="margin-left: 20px;">
            <a-form-item label="客户经理" :labelCol="{ span: 6 }" :wrapperCol="{ span: 12, offset: 1 }">
              <a-select v-model="paramsObj.staffIdlist" style="width: 300px" placeholder="请选择客户经理"
                        :filter-option="(input, option) => { return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0; }"
                        :default-value="null" mode="multiple" option-label-prop="label">
                <a-select-option :value="item.id" :label="item.name" v-for=" (item, index) in userData" :key="index">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </div>
          <div style="margin-left: 80px;">
            <a-button type="primary" style="margin-top: 5px;margin-right: 5px;" @click="search">查询</a-button>
            <a-button type="primary" v-if="hasPermission('EXPORT_COUNT_INTERCOM_RECORD_REGION_TIME_TREND')"
                      style="margin-top: 5px;" @click="exportExecl">导出Excel
            </a-button>
          </div>

        </div>
      </a-form>
    </div>
    <div>
      <div id="chart" v-if="hasPermission('COUNT_INTERCOM_RECORD_TIME_TREND_ICON')" style="height: 35vh;"></div>
      <div style="min-height: 50vh;background: #fff;" v-if="hasPermission('INTERCOM_RECORD_LIST')">
        <standard-table :columns="columns" :dataSource="dataSource" :loading="tableLoading" :pagination="false">
            <span slot="dialoutDealingNumberTotal">
              外呼去电接听总数<br>
              合计：{{ tabObj.dialoutDealingNumberTotal }}
            </span>
          <span slot="dialoutNotDealNumberTotal">
              外呼振铃未接听数<br>
              合计：{{ tabObj.dialoutNotDealNumberTotal }}
            </span>
          <span slot="dialoutFailedNumberTotal">
              外呼失败数<br>
              合计：{{ tabObj.dialoutFailedNumberTotal }}
            </span>
          <span slot="dialoutNumberTotal">
              外呼总数<br>
              合计：{{ tabObj.dialoutNumberTotal }}
            </span>
          <span slot="totalCallTimeLengthTotal">
              通话总时长<br>
              合计：{{ formTime(tabObj.totalCallTimeLengthTotal) }}
            </span>
<!--          <span slot="avgLengthTotal">-->
<!--              平均外呼通话时长<br>-->
<!--              合计：{{ tabObj.avgLengthTotal}}-->
<!--            </span>-->
        </standard-table>

      </div>
    </div>


  </a-card>
</template>

<script>
import StandardTable from '@/components/table/StandardTable'
import moment from 'moment';
import 'moment/locale/zh-cn';
import {request} from '@/utils/request'
import * as echarts from 'echarts';
import {totalTime} from '@/utils/util'

const columns = [
  {
    title: '通话发起时间',
    dataIndex: 'offeringTime',
    align: 'center'
  },
  {
    // title: '外呼总数',
    dataIndex: 'dialoutNumber',
    slots: { title: 'dialoutNumberTotal' },
    align: 'center'
  },
  {
    title: '来电总数',
    dataIndex: 'normalNumber',
    align: 'center'
  },
  {
    // 外呼去电
    dataIndex:'dialoutDealingNumber',
    slots: { title: 'dialoutDealingNumberTotal' },
    align: 'center'
  },
  {
    title: '来电接听数',
    dataIndex: 'normalDealingNumber',
    align: 'center'
  },
  {
    // 外呼振铃未接听数
    dataIndex:'dialoutNotDealNumber',
    slots: { title: 'dialoutNotDealNumberTotal' },
    align: 'center'
  },

  {
    title: '来电振铃未接听数',
    dataIndex: 'normalNotDealNumber',
    align: 'center'
  },
  {
    // 外呼失败数
    // title: '外呼失败数',
    dataIndex: 'dialoutFailedNumber',
    slots: { title: 'dialoutFailedNumberTotal' },
    align: 'center'
  },
  {
    title: '来电失败数',
    dataIndex: 'normalFailedNumber',
    align: 'center'
  },
  {
    // title: '通话总时长',
    dataIndex: 'totalCallTimeLength',
    slots: { title: 'totalCallTimeLengthTotal' },
    align: 'center'
  },
  {
    title: '平均通话时长',
    dataIndex: 'avgLength',
    // slots: { title: 'avgLengthTotal' },
    align: 'center'
  }
]

export default {
  name: 'workloadList',
  components: {StandardTable},
  data() {
    return {
     tabObj:{},
      moment,
      customerData: [],
      userData: [],
      advanced: true,
      columns: columns,
      dataSource: [],
      selectedRows: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0
      },
      // 筛选
      queryData: {
        page: 0,
        size: 10,
        "dateMin": "",
        "dateMax": "",
      },
      tableLoading: false,
      downLoading: false,
      tabCurrent: 1,
      downCurrent: 1,
      downTotal: 0,
      tabTotal: 0,
      paramsObj: {
        type: 1,
        staffIdlist: []
      },
      currentYear: "",
      currentMonth: "",
      currentDate: "",
      open: false,
      form: {},
      selectedYear: moment().format('YYYY')
    }
  },
  mounted() {
    this.initData()
    this.getUserList()
  },
  computed: {
    userPermissions() {
      return this.$store.state.account.user.authorityList;
    }
  },
  methods: {
    formTime(totalSeconds){
      const hours = Math.floor(totalSeconds / 3600);
      const minutes = Math.floor((totalSeconds % 3600) / 60);
      const seconds = totalSeconds % 60;
      const timeString = `${hours}小时 ${minutes}分钟 ${seconds}秒`;
      return timeString
    },
    // 销售列表
    getUserList() {
      request(process.env.VUE_APP_API_BASE_URL + 'sfa/users/list', 'post').then(res => {
        if (res.data.code == 200) {
          this.userData = res.data.data
        } else {
          this.$message.error(res.data.message)
        }
      })
    },
    handleYearChange(date, dateString) {
      this.selectedYear = dateString;
    },
    initData() {
      const date = new Date();
      this.currentYear = date.getFullYear();
      this.currentMonth = date.getMonth() + 1;
      this.currentDate = date.getDate();
      this.totalDate = this.currentYear + '-' + this.currentMonth + '-' + this.currentDate;
      this.paramsObj.time = this.currentYear + '-' + this.currentMonth + '-' + this.currentDate
      this.getData();//表格数据
      this.getList();//列表数据
    },
    search() {
      this.getData();//表格数据
      this.getList();//列表数据

    },
    initEchart() {
      // 基于准备好的dom，初始化echarts实例
      const myChart = echarts.init(document.getElementById('chart'))
      // 指定图表的配置项和数据
      const option = {
        title: {
          text: '外呼通话趋势',
          left: 'center',
        },
        grid: {
          right: "0%",
          left: "0%",
        },
        tooltip: {},
        legend: {
          data: ['外呼成功数', '外呼振铃未接听', '外呼失败数', '外呼总数'],
          bottom: 0
        },
        calculable: true,
        xAxis: [
          {
            type: 'category',
            data: this.xAxis
          }
        ],
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: [
          {
            name: '外呼成功数',
            type: 'line',
            data: this.dialoutDealingNumber,
          },
          {
            name: '外呼振铃未接听',
            type: 'line',
            data: this.dialoutNotDealNumber,
          },
          {
            name: '外呼失败数',
            type: 'line',
            data: this.dialoutFailedNumber,
          },
          {
            name: '外呼总数',
            type: 'line',
            data: this.outTotalNumber,
          }
        ]
      }
      // 使用刚指定的配置项和数据显示图表
      myChart.setOption(option)
    },
    // 导出exlce
    exportExecl() {
      if (this.dataSource.length == 0) {
        this.$message.error('没有可导出的数据！')
        return;
      }
      let params = this.paramsObj;
      params.remarks = '导出'
      request(process.env.VUE_APP_API_BASE_URL + 'sfa/intercomrecord/exportCountIntercomRecordRegionTimeTrend', 'post', params).then(res => {
        if (res.data.code == 200) {
          this.$message.success('导出成功，请前往个人中心-导出任务列表查看！', 3)
        } else {
          this.$message.error(res.data.message)
        }
      })
    },
    hasPermission(permission) {
      return this.userPermissions.includes(permission);
    },
    switchDate(e) {
      switch (e) {
        case "1":
          this.paramsObj = {
            time: this.paramsObj.time = this.totalDate,
            type: e
          }
          break;
        case "2":
          this.paramsObj = {
            time: this.paramsObj.time = this.currentYear + '-' + this.currentMonth,
            type: e
          }
          break;
        case "3":
          this.paramsObj = {
            time: this.currentYear,
            type: e
          }
          break;
        case "4":
          this.paramsObj = {
            beginTime: this.totalDate,
            endTime: this.totalDate,
            type: 4,
          }
          break;
      }
    },
    // 弹出日历和关闭日历的回调
    openChangeOne(status) {
      if (status) {
        this.open = true
      } else {
        this.open = false
      }
    },
    // 得到年份选择器的值
    panelChangeOne(value) {
      this.selectedYear = moment(value).format('YYYY');
      this.paramsObj.time = this.selectedYear;
      this.open = false;
    },
    // 选择日期
    dateChange(date, dateString) {
      if (this.paramsObj.type == 4) {
        this.paramsObj.beginTime = dateString[0].toString().replace(/\//g, '-');
        this.paramsObj.endTime = dateString[1].toString().replace(/\//g, '-');
      } else {
        this.paramsObj = {
          time: dateString,
          type: this.paramsObj.type
        }
      }
    },

    formatTime(time) {
      const moment = require('moment');
      // 使用 moment.js 解析时间字符串
      const parsedTime = moment(time, 'YYYY-MM');
      // 判断是否存在月份
      if (parsedTime.isValid() && parsedTime.month() !== undefined) {
        // 获取月份
        const month = parsedTime.month() + 1; // moment.js 中的月份是从0开始计数，所以要加1
        // 判断月份是否小于10
        if (month < 10) {
          // 补零操作
          this.paramsObj.time = parsedTime.format('YYYY-0M');
        }
      }
      return time;
    },
    // 获取列表 
    getData() {
      if (this.paramsObj.type == 2) {
        this.formatTime(this.paramsObj.time)
      }
      if (this.paramsObj.type == 1) {
        this.paramsObj.time = this.formatDay(this.paramsObj.time)
      }
      request(process.env.VUE_APP_API_BASE_URL + 'sfa/intercomrecord/countIntercomRecordRegionTimeTrendIcon', 'post', this.paramsObj).then(res => {
        let data = res.data.data;
        if (res.data.code == 200) {
          this.xAxis = data.map(obj => obj.offeringTime);
          this.dialoutDealingNumber = data.map(obj => obj.dialoutDealingNumber);
          this.dialoutNotDealNumber = data.map(obj => obj.dialoutNotDealNumber);
          this.dialoutFailedNumber = data.map(obj => obj.dialoutFailedNumber);
          this.outTotalNumber = data.map(obj => obj.outTotalNumber);
          this.initEchart()
        } else {
          this.$message.error(res.data.message)
        }
      })
    },
    formatDay(timeString){
      const dateObj = new Date(timeString);
      let month = dateObj.getMonth() + 1; // 月份从0开始，需要加1
      let day = dateObj.getDate();
      month = month < 10 ? '0' + month : month;
      day = day < 10 ? '0' + day : day;
      return `${dateObj.getFullYear()}-${month}-${day}`;
    },
    getList() {
      if (this.paramsObj.type == 2) {
        this.formatTime(this.paramsObj.time)
      }
      this.tableLoading = true;
      request(process.env.VUE_APP_API_BASE_URL + 'sfa/intercomrecord/countIntercomRecordRegionTimeTrend', 'post', this.paramsObj).then(res => {
        this.tableLoading = false;
        this.tabObj = res.data.data;
        let data = res.data;
        data.data.countIntercomRecordTimeTrendVoList.forEach(item => {
          item.avgLength = totalTime(item.avgLength);
          item.totalCallTimeLength = totalTime(item.totalCallTimeLength);
        })
        if (res.data.code == 200) {
          this.dataSource = data.data.countIntercomRecordTimeTrendVoList;
        } else {
          this.$message.error(res.data.message)
        }
      })
    },

    tabPageChange(page) {
      this.queryData.page = page - 1;
      this.tabCurrent = page;
      this.getData()
    },
    // 格式化年月日
    formatDate(timestamp) {
      const date = new Date(timestamp);
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      let result = year + '-' + month + '-' + day
      return result
    },
  }
}
</script>

<style lang="less" scoped>
.search {
  // margin-bottom: 54px;
}

.fold {
  width: calc(100% - 216px);
  display: inline-block
}

.operator {
  margin-bottom: 18px;
}

@media screen and (max-width: 900px) {
  .fold {
    width: 100%;
  }
}

::v-deep .page-content {
  padding: 0 !important;
}

.cre-btn {
  width: 100%;
  height: 64px;
  line-height: 64px;
  background: #EEECFE;
  border-radius: 4px;
  border: 1px dashed #5542F6;
  cursor: pointer;
  font-size: 16px;
  font-family: PingFangHK-Regular, PingFangHK;
  font-weight: 400;
  color: #5542F6;
}

.btn-box button {
  width: 106px;
  height: 36px;
  margin-left: 10px;
}


.page-box {
  display: flex;
  justify-content: right;
  padding-right: 46px;
  margin-top: 10px;
}

::v-deep .ant-form-item {
  display: flex !important;
}
</style>
